<template>
  <ContainerHub class="hub-travels">
    <template slot="header">
      <HubHeader
        :titleTag="'h1'"
        :title="title"
        :email="email"
        :description="description" />
    </template>

    <template slot="body">
      <CardUser v-if="user" :display-type="'travel'" :content="user" />
      <HubHeader
        class="travels-details__title"
        :titleTag="'h3'"
        :title="$t('message.travels.overview.title')" />
      <ElementTable class="table--travel" v-if="overview">
        <template slot="header">
          <TravelsTableHeader />
        </template>
        <template slot="body">
          <TravelsTableBody v-for="(item, index) in overview" :content="item" :key="`overview-${index}`" />
        </template>
      </ElementTable>

      <BaseButton
        icon="more"
        icon-position="right"
        color="primary"
        v-if="offset < total"
        @click.native="handleClick">
          {{ $t(`message.common.buttons.viewMore`) }}
      </BaseButton>
    </template>
  </ContainerHub>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseButton from '@base/BaseButton'
import ContainerHub from '@containers/ContainerHub'
import HubHeader from './HubHeader'
import CardUser from '@cards/user/CardUser'
import ElementTable from '@elements/ElementTable'
import TravelsTableHeader from '@blocks/travels/TravelsTableHeader'
import TravelsTableBody from '@blocks/travels/TravelsTableBody'

export default {
  name: 'HubTravels',
  props: {
    name: {
      type: String,
      required: true
    },
    users: {
      type: Array,
      required: true
    },
    overview: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selected: ''
    }
  },
  computed: {
    ...mapGetters({
      title: 'page/getTitle',
      description: 'page/getDescription',
      email: 'page/getEmail',
      offset: 'holidays/getOffsetOverview',
      total: 'holidays/getTotalOverview'
    }),
    displayType () {
      return 'large'
    }
  },
  components: {
    BaseButton,
    HubHeader,
    ContainerHub,
    ElementTable,
    CardUser,
    TravelsTableHeader,
    TravelsTableBody
  },
  methods: {
    ...mapActions({
      fetchGetAddTravelsOverview: 'page/fetchGetAddTravelsOverview'
    }),
    handleClick () {
      this.fetchGetAddTravelsOverview()
    }
  }
}
</script>

<style lang="scss">
  .hub-travels{
    .container-hub__body{
      .button{
        @media print {
          display: none !important;
        }
      }
    }
  }
</style>
